@mixin mobile {
  @media all and (min-width: 480px) {
    @content;
  }
}

@mixin tablet {
  @media all and (min-width: 768px) {
    @content;
  }
}

@mixin desktop {
  @media all and (min-width: 1200px) {
    @content;
  }
}

@mixin box-sizing($box-model) {
  box-sizing: $box-model;
}
