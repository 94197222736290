// Colours
$main_color: #014495;
$secondary_color: #e95d11;

// Fonts
$title_font: "Oswald", sans-serif;
$content_font: "Fira Sans", sans-serif;

// Z-Indexes
$z_index_below: -10;
$z_index_1: 10;
$z_index_2: 20;
$z_index_3: 30;
